const StaggeredFade = ({ children, delay = 0, isVisible = false }) => (
    <div
        className={`opacity-0 translate-y-4 transition-all duration-500 ${isVisible ? 'animate-fadeInUp' : ''
            }`}
        style={{
            animationDelay: `${delay}s`
        }}
    >
        {children}
    </div>
);

export default StaggeredFade;