import React from 'react';

function Splitter({ orientation = 'center' }) {
  return (
    <div className={`w-full py-12  `}>
      <div className="container mx-auto px-6">
        <div className={`flex items-center justify-${orientation} space-x-4`}>
          <div className="flex-grow h-[1px] bg-gradient-to-r from-transparent via-gray-200 dark:via-gray-700 to-transparent" />
          <div className="flex space-x-2">
            <span className="h-2 w-2 rounded-full bg-blue-600 dark:bg-blue-400 animate-pulse" />
            <span className="h-2 w-2 rounded-full bg-purple-600 dark:bg-purple-400 animate-pulse delay-75" />
            <span className="h-2 w-2 rounded-full bg-blue-600 dark:bg-blue-400 animate-pulse delay-150" />
          </div>
          <div className="flex-grow h-[1px] bg-gradient-to-r from-transparent via-gray-200 dark:via-gray-700 to-transparent" />
        </div>
      </div>
    </div>
  );
}

export default Splitter;