import { useState, useEffect } from 'react';
import { IconSun, IconMoon, IconHome, IconUser, IconBriefcase, IconBolt, IconMail } from '@tabler/icons-react';
import { useTheme } from '../context/ThemeContext';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';
import StaggeredFade from '../animations/StaggeredFade';
import { scrollToSection } from '../utils/scroll';

const menuItems = [
  { href: '#home', label: 'Home', icon: <IconHome size={20} stroke={1.5} /> },
  { href: '#about', label: 'About', icon: <IconUser size={20} stroke={1.5} /> },
  { href: '#projects', label: 'Projects', icon: <IconBriefcase size={20} stroke={1.5} /> },
  { href: '#skills', label: 'Skills', icon: <IconBolt size={20} stroke={1.5} /> },
  { href: '#contact', label: 'Contact', icon: <IconMail size={20} stroke={1.5} /> }
];

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState('home');
  const { darkMode, toggleDarkMode } = useTheme();
  const [isAnimating, setIsAnimating] = useState(false);
  const [sectionRef, isVisible] = useIntersectionObserver();

  useEffect(() => {
    // Disable body scroll when menu is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
      const sections = ['home', 'about', 'projects', 'skills', 'contact'];
      const current = sections.find(section => {
        const element = document.getElementById(section);
        if (element) {
          const bounds = element.getBoundingClientRect();
          return bounds.top <= 100 && bounds.bottom >= 100;
        }
        return false;
      });
      if (current) setActiveSection(current);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleMenuClick = (e) => {
    e.preventDefault();
    setIsOpen(false);
    const sectionId = e.currentTarget.getAttribute('href').slice(1);
    scrollToSection(sectionId);
  };

  const MobileThemeToggle = ({ darkMode, isAnimating, onClick }) => (
    <button
      onClick={() => {
        setIsAnimating(true);
        setTimeout(() => {
          toggleDarkMode();
          setIsAnimating(false);
        }, 150);
      }}
      aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
      className="md:hidden fixed top-4 right-4 z-50 p-3 rounded-xl backdrop-blur-lg shadow-lg transition-all duration-300 
    bg-white/80 dark:bg-gray-800/80 border border-gray-200/50 dark:border-gray-700/50
    hover:shadow-xl hover:-translate-y-1"
    >
      <div className="relative w-4 h-4 flex items-center justify-center">
        <div
          className={`
            transform transition-all duration-300 absolute inset-0 flex items-center justify-center
            ${isAnimating ? "scale-50 opacity-0 rotate-180" : "scale-100 opacity-100 rotate-0"}
          `}
        >
          {darkMode ? (
            <IconSun className="w-5 h-5 text-yellow-500" />
          ) : (
            <IconMoon className="w-5 h-5 text-gray-600" />
          )}
        </div>

        <div
          className={`
            transform transition-all duration-300 absolute inset-0 flex items-center justify-center
            ${isAnimating ? "scale-100 opacity-100 rotate-0" : "scale-50 opacity-0 rotate-180"}
          `}
        >
          {darkMode ? (
            <IconMoon className="w-5 h-5 text-gray-600" />
          ) : (
            <IconSun className="w-5 h-5 text-yellow-500" />
          )}
        </div>
      </div>
    </button>
  );


  return (
    <header ref={sectionRef} className="fixed z-50 w-full transition-all duration-500">
      {/* Top navigation */}
      <nav className={`
        container mx-auto px-6 transition-all duration-500
        ${scrolled ? 'opacity-0 pointer-events-none' : 'opacity-100'}
      `}>
        <StaggeredFade delay={0.1} isVisible={isVisible}>
          <div className="flex items-center justify-between py-6">
            <a href="#home" className="text-2xl font-bold text-gray-800 dark:text-white">
              <div className="flex items-center gap-2 group cursor-pointer">
                {/* Logo Mark */}
                <div className="relative w-8 h-8 sm:w-10 sm:h-10">
                  {/* Animated Background Layers */}
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg 
      rotate-45 transform group-hover:rotate-90 transition-transform duration-700">
                    <div className="absolute inset-0.5 bg-white dark:bg-gray-900 rounded-lg" />
                    <div className="absolute inset-1 bg-gradient-to-br from-blue-500 to-purple-500 rounded-lg opacity-75" />
                  </div>

                  {/* Floating Particles */}
                  <div className="absolute -inset-1 opacity-0 group-hover:opacity-100 transition-opacity duration-700">
                    <div className="absolute top-0 left-0 w-2 h-2 bg-blue-400 rounded-full animate-ping" />
                    <div className="absolute bottom-0 right-0 w-2 h-2 bg-purple-400 rounded-full animate-ping delay-150" />
                    <div className="absolute top-1/2 right-0 w-1.5 h-1.5 bg-cyan-400 rounded-full animate-ping delay-300" />
                  </div>

                  {/* Logo Letter */}
                  <div className="absolute inset-0 flex items-center justify-center text-lg sm:text-xl font-bold text-white mb-1">
                    A
                  </div>
                </div>

                {/* Logo Text */}
                <div className="flex items-baseline">
                  <span className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 
      bg-clip-text text-transparent group-hover:tracking-wider transition-all duration-700">
                    yman
                  </span>
                  <span className="ml-0.5 text-2xl sm:text-3xl font-bold text-blue-600 dark:text-blue-400 
      group-hover:rotate-12 transition-transform duration-500">
                    .
                  </span>
                </div>
              </div>
            </a>

            <div className="hidden md:flex items-center gap-8">
              {menuItems.map((item, index) => (
                <StaggeredFade delay={0.2 + index * 0.1} key={item.href} isVisible={isVisible}>
                  <a
                    href={item.href}
                    onClick={handleMenuClick}
                    className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                  >
                    {item.label}
                  </a>
                </StaggeredFade>
              ))}
              <StaggeredFade delay={0.6} isVisible={isVisible}>
                <button
                  onClick={() => {
                    setIsAnimating(true);
                    setTimeout(() => {
                      toggleDarkMode();
                      setIsAnimating(false);
                    }, 150);
                  }}
                  aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
                  className="relative p-2 rounded-lg overflow-hidden"
                >
                  <div className={`
    transform transition-all duration-300 relative w-5 h-5 flex items-center justify-center
    ${isAnimating ? 'scale-50 opacity-0 rotate-180' : 'scale-100 opacity-100 rotate-0'}
  `}>
                    {darkMode ? (
                      <IconSun className="w-5 h-5 text-yellow-500" />
                    ) : (
                      <IconMoon className="w-5 h-5 text-gray-600" />
                    )}
                  </div>

                  <div className={`
    transform transition-all duration-300 absolute inset-0 flex items-center justify-center
    ${isAnimating ? 'scale-100 opacity-100 rotate-0' : 'scale-50 opacity-0 rotate-180'}
  `}>
                    {darkMode ? (
                      <IconMoon className="w-5 h-5 text-gray-600" />
                    ) : (
                      <IconSun className="w-5 h-5 text-yellow-500" />
                    )}
                  </div>
                </button>
              </StaggeredFade>
            </div>
          </div>
        </StaggeredFade>
      </nav>

      {/* Floating side navigation */}
      <div className={`
        fixed right-8 top-1/2 -translate-y-1/2 transition-all duration-500
        ${scrolled ? 'translate-x-0 opacity-100' : 'translate-x-20 opacity-0'}
      `}>
        <StaggeredFade delay={0.3} isVisible={isVisible}>
          <nav className="hidden md:block">
            <div className="flex flex-col gap-4 p-4 rounded-2xl bg-white/80 dark:bg-gray-900/80 backdrop-blur-lg shadow-lg">
              <button
                onClick={() => {
                  setIsAnimating(true);
                  setTimeout(() => {
                    toggleDarkMode();
                    setIsAnimating(false);
                  }, 150);
                }}
                aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
                className="relative p-2 rounded-lg  overflow-hidden"
              >
                <div className={`
    transform transition-all duration-300 absolute inset-0 flex items-center justify-center
    ${isAnimating ? 'scale-50 opacity-0 rotate-180' : 'scale-100 opacity-100 rotate-0'}
  `}>
                  {darkMode ? (
                    <IconSun className="w-5 h-5 text-yellow-500" />
                  ) : (
                    <IconMoon className="w-5 h-5 text-gray-600" />
                  )}
                </div>

                <div className={`
    transform transition-all duration-300 absolute inset-0 flex items-center justify-center
    ${isAnimating ? 'scale-100 opacity-100 rotate-0' : 'scale-50 opacity-0 rotate-180'}
  `}>
                  {darkMode ? (
                    <IconMoon className="w-5 h-5 text-gray-600" />
                  ) : (
                    <IconSun className="w-5 h-5 text-yellow-500" />
                  )}
                </div>
              </button>
              {menuItems.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  onClick={handleMenuClick}
                  className="group relative flex items-center gap-2"
                  title={item.label}
                >
                  <span className={`
                  p-3 rounded-xl transition-all duration-300
                  ${activeSection === item.href.slice(1)
                      ? 'bg-blue-100 dark:bg-blue-900/50 text-blue-600 dark:text-blue-400'
                      : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800'
                    }
                `}>
                    {item.icon}
                  </span>
                  <span className={`
                  absolute right-full mr-2 px-2 py-1 rounded-lg text-sm font-medium
                  bg-gray-900 dark:bg-gray-700 text-white whitespace-nowrap
                  opacity-0 translate-x-2 pointer-events-none
                  group-hover:opacity-100 group-hover:translate-x-0
                  transition-all duration-300
                `}>
                    {item.label}
                  </span>
                </a>
              ))}
            </div>
          </nav>
        </StaggeredFade>
      </div>

      {/* Mobile Menu Overlay */}
      <div
        className={`fixed inset-0 bg-gray-800/50 dark:bg-black/50 backdrop-blur-sm transition-opacity duration-300 md:hidden ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        onClick={() => setIsOpen(false)}
      />

      {/* Mobile Menu Panel */}
      <div
        className={`fixed inset-y-0 right-0 w-[300px] bg-white dark:bg-gray-900 shadow-2xl md:hidden transition-all duration-300 transform ${isOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
      >
        <div className="h-full flex flex-col">
          {/* Menu Items */}
          <div className="flex-1 overflow-y-auto py-8 px-6">
            <div className="space-y-2">
              {menuItems.map((item) => (
                <a
                  key={item.href}
                  href={item.href}
                  onClick={handleMenuClick}
                  className={`group flex items-center gap-3 px-4 py-3 rounded-xl text-lg font-medium transition-all duration-300 ${activeSection === item.href.slice(1)
                    ? 'bg-blue-50 dark:bg-blue-900/30 text-blue-600 dark:text-blue-400'
                    : 'hover:bg-gray-50 dark:hover:bg-gray-800 text-gray-600 dark:text-gray-300'
                    }`}
                >
                  <span className="text-xl transition-transform duration-300 group-hover:scale-110">
                    {item.icon}
                  </span>
                  {item.label}
                </a>
              ))}
            </div>
          </div>

          {/* Bottom Section */}
          <div className="p-6 border-t border-gray-100 dark:border-gray-800">
            <p className="text-sm text-gray-500 dark:text-gray-400">
              © 2024 YourName. All rights reserved.
            </p>
          </div>
        </div>
      </div>

      {/* Add mobile theme toggle */}
      <MobileThemeToggle
        darkMode={darkMode}
        isAnimating={isAnimating}
        onClick={() => {
          setIsAnimating(true);
          setTimeout(() => {
            toggleDarkMode();
            setIsAnimating(false);
          }, 150);
        }}
      />
    </header>
  );
}

export default Header;