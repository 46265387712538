
import { IconBrandGithub, IconBrandLinkedin, IconBrandTwitter } from '@tabler/icons-react';

export const socialLinks = [
    {
      icon: <IconBrandGithub className="w-6 h-6" />,
      href: "https://github.com/aymanbest",
      label: "GitHub Profile",
      color: "text-gray-900 dark:text-gray-100"
    },
    // {
    //   icon: <IconBrandLinkedin className="w-6 h-6" />,
    //   href: "https://linkedin.com",
    //   label: "LinkedIn Profile",
    //   color: "text-sky-900 dark:text-sky-600"
    // },
    // {
    //   icon: <IconBrandTwitter className="w-6 h-6" />,
    //   href: "https://twitter.com",
    //   label: "Twitter Profile",
    //   color: "text-sky-500 dark:text-sky-400"
    // }
  ];
