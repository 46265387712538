import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [darkMode, setDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' || (!savedTheme && window.matchMedia('(prefers-color-scheme: dark)').matches);
  });

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode(!darkMode);

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}> 
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}

export const sectionClasses = {
    light: {
      primary: "bg-white", 
      secondary: "bg-gray-50",
      gradient: "bg-gradient-to-br from-blue-50 via-white to-purple-50",
      decorative: "after:absolute after:inset-0 after:bg-grid-gray-200/50" 
    },
    dark: {
      primary: "dark:bg-gray-900",
      secondary: "dark:bg-gray-800", 
      gradient: "dark:bg-gradient-to-br dark:from-gray-900 dark:via-gray-800 dark:to-gray-900",
      decorative: "after:absolute after:inset-0 after:bg-grid-white/10"
    }
};