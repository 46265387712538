import React, { useRef, useState } from 'react';
import { IconMail, IconArrowRight, IconCode } from '@tabler/icons-react';
import { useTheme } from '../context/ThemeContext';
import { socialLinks } from '../data/sociallinks';
import { scrollToSection } from '../utils/scroll';
import { useVisitorCount } from '../hooks/useVisitorCount';
import { IconArrowUp } from '@tabler/icons-react';
import { RandomizedTextEffect } from './text-randomized';
import { TypingAnimation } from './TypingAnimation';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';

function Footer() {
    const [scrolled, setScrolled] = useState(false);
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { darkMode } = useTheme();
    const [sectionRef, isVisible] = useIntersectionObserver();
    const { visitorCount, loading } = useVisitorCount();

    const scrollToTop = () => {
        scrollToSection('home');
    };

    // Handle scroll event
    React.useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 200);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // Handle newsletter submission
    const handleSubscribe = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        await new Promise(resolve => setTimeout(resolve, 1500));
        setIsSubmitting(false);
        setEmail('');
    };

    return (
        <footer ref={sectionRef} className="relative bg-gradient-to-t from-gray-50 dark:from-gray-900/50 to-transparent">
            {/* Scroll to Top Button */}
            <button
                onClick={scrollToTop}
                className={`fixed bottom-6 right-6 z-50 p-3 rounded-full shadow-lg transition-all duration-500 
                    ${scrolled ? 'translate-y-0 opacity-100' : 'translate-y-16 opacity-0'}
                    ${darkMode ? 'bg-gray-800 hover:bg-gray-700' : 'bg-white hover:bg-gray-50'} 
                    group`}
            >
                <IconArrowUp className="w-6 h-6 text-blue-500 group-hover:animate-bounce" />
            </button>

            <div className="container mx-auto px-4 py-12">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    {/* Left Column - Newsletter */}
                    <div>
                        <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
                            Let's do great work together
                        </h2>
                        <div className="max-w-md">
                            <p className="text-lg text-gray-600 dark:text-gray-400 mb-4">
                                Sign up for our newsletter*
                            </p>
                            <form onSubmit={handleSubscribe} className="relative">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Your email"
                                    className="w-full px-6 py-3 pr-12 bg-white dark:text-white dark:bg-gray-800 border-2 border-gray-200 dark:border-gray-700 rounded-full focus:outline-none focus:border-blue-500"
                                />
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="absolute right-2 top-1/2 -translate-y-1/2 p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors"
                                >
                                    <IconArrowRight className="w-5 h-5" />
                                </button>
                            </form>
                        </div>
                    </div>

                    {/* Right Column - Social Links */}
                    <div>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
                            Connect With Us
                        </h3>
                        <div className="flex gap-4">
                            {socialLinks.map((social, index) => (
                                <a
                                    key={index}
                                    href={social.href}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={`p-3 rounded-lg transition-all duration-300 hover:scale-110 ${social.color}`}
                                >
                                    {social.icon}
                                </a>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Scrolling Text Animation */}
                <div className="overflow-hidden">
                    <div className="flex justify-center">
                        {isVisible && (
                            <TypingAnimation
                                text="FaikAyman.me"
                                className="text-[8vw] font-bold tracking-[-0.07em] leading-[1.2] py-2 /* Added leading and padding */
                                    text-transparent bg-clip-text bg-gradient-to-r 
                                    from-blue-600 to-purple-600 dark:from-blue-400 dark:to-purple-400"
                            />
                        )}
                    </div>
                </div>

                
                {/* <div className="flex justify-center mb-4">
                    <div className="flex items-center gap-2 px-4 py-2 bg-white/80 dark:bg-gray-800/80 rounded-full shadow-sm">
                        <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                            {loading ? (
                                "Counting visitors..."
                            ) : (
                                `${visitorCount.toLocaleString()} visitors`
                            )}
                        </span>
                    </div>
                </div>


                <div className="text-center">
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                        {isVisible && (
                            <RandomizedTextEffect
                                text={`© ${new Date().getFullYear()} FaikAyman.me. All rights reserved.`}
                            />
                        )}
                    </p>
                </div> */}
            </div>
        </footer>
    );
}

export default Footer;