import React, { Suspense } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { sectionClasses } from '../context/ThemeContext';

// Animation wrapper component
const AnimatedSection = ({ children }) => (
  <div className="animate-fade-in opacity-0" 
       style={{
         animation: 'fadeIn 0.5s ease-out forwards',
         animationDelay: '0.2s'
       }}>
    {children}
  </div>
);

// Loading fallback
const LoadingFallback = () => (
  <div className="flex items-center justify-center min-h-[200px]">
    <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
  </div>
);

function MainLayout({ children }) {
  return (
    <div className={`
      min-h-screen flex flex-col relative overflow-hidden
      ${sectionClasses.light.primary}
      ${sectionClasses.dark.primary}
      ${sectionClasses.light.gradient}
      ${sectionClasses.dark.gradient}
      ${sectionClasses.light.decorative}
      ${sectionClasses.dark.decorative}`}>
      
      {/* Animated background */}
      <div className="absolute inset-0 bg-gradient-to-b from-blue-50/50 to-white dark:from-gray-900/50 dark:to-gray-900 -z-10">
        <div className="absolute inset-0 bg-grid-slate-200 dark:bg-grid-slate-700 opacity-20" />
      </div>

      {/* Content */}
      <div className="relative z-10">
        <Header />
        <main className="flex-grow">
          <Suspense fallback={<LoadingFallback />}>
            <AnimatedSection>
              {children}
            </AnimatedSection>
          </Suspense>
        </main>
      </div>
    </div>
  );
}

export default MainLayout;