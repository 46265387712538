import React, { lazy, Suspense } from 'react';
import { ThemeProvider } from './context/ThemeContext';
import MainLayout from './layouts/MainLayout';
import Splitter from './components/Splitter';

// Lazy load components
const Hero = lazy(() => import('./components/Hero'));
const About = lazy(() => import('./components/About'));
const Projects = lazy(() => import('./components/Projects'));
const Skills = lazy(() => import('./components/Skills'));
const Contact = lazy(() => import('./components/Contact'));
const Footer = lazy(() => import('./components/Footer'));

function App() {
  return (
    <ThemeProvider>
      <MainLayout>
        <Suspense fallback={null}>
          <Hero />
          <Splitter />
          <About />
          <Splitter />
          <Projects />
          <Splitter />
          <Skills />
          <Splitter />
          <Contact />
          <Splitter />
          <Footer />
        </Suspense>
      </MainLayout>
    </ThemeProvider>
  );
}

export default App;